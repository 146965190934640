<template>
  <div class="calendar_price">
    <el-calendar v-model="chooseDate" ref="priceCalendar" :first-day-of-week="7">
      <template
        slot="dateCell"
        slot-scope="{date, data}">
        <div class="calendar_day">
          <div class="calendar_confirm_box">
            <span class="calendar_day_top" :class="{isOpacity: !isDateVisible(data.day)}">{{data.day.split('-').pop()}}</span>
            <div class="calendar_confirm" v-if="inputDatePriceVisible(data.day)">
              <el-button type="danger" icon="el-icon-close" circle @click="closeSingPrice(data.day)"></el-button>
              <el-button type="primary" icon="el-icon-check" circle @click="submitSingPrice(data.day)"></el-button>
            </div>
          </div>
          <div @dblclick="setDatePrice(data.day)">
            <div class="calendar_day_price" v-if="isDateVisible(data.day) && !inputDatePriceVisible(data.day)">
              <span>{{getPrice(data)}}</span>
              <span>{{getCostPrice(data)}}</span>
            </div>
            <div v-if="inputDatePriceVisible(data.day)">
              <el-input class="margintop5" :placeholder="$t('form.hotel.price2')" v-model="createData[chooseIndex].price"></el-input>
              <el-input class="margintop5" :placeholder="$t('form.hotel.costPrice')" v-model="createData[chooseIndex].cost_price"></el-input>
            </div>
          </div>
        </div>
      </template>
    </el-calendar>
    <el-card>
      <alter-form labelWidth="140px" ref="alterForm" @formSubmit="formSubmit" :modifyData="modifyData" :formData="formData" :collapseLength="collapseLength"></alter-form>
    </el-card>
  </div>
</template>

<script>
// import cmsCalendar from './calendar'
import moment from 'moment'
import jsonData from './jsonData'
import alterForm from '@/components/alterForm'
import { getPolicyCalendarPrice, postPolicyCalendarPrice } from '@/services/hotel'
import Utils from '@/utils/Utils.js'
import loading from '@/utils/loading.json'
export default {
  components: {
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      chooseDate: new Date(),
      createData: [],
      chooseIndex: 0
    }
  },
  created () {
    this.init()
    this.addEvent()
  },
  mounted () {
  },
  destroyed () {
    this.prevBtn.removeEventListener('click', e => this.init())
    this.nextBtn.removeEventListener('click', e => this.init())
    this.today.removeEventListener('click', e => this.init())
  },
  watch: {
    chooseDate (n) {
      if (this.$refs.alterForm) {
        this.$refs.alterForm.createDate.begin_time = moment(n).format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    inputDatePriceVisible (data) {
      if (this.createData[this.chooseIndex] && this.createData[this.chooseIndex].date && data === this.createData[this.chooseIndex].date && this.createData[this.chooseIndex].visible) return true
      return false
    },
    setDatePrice (data) {
      this.chooseIndex = this.createData.findIndex(item => item.date === data)
      this.createData[this.chooseIndex].visible = true
    },
    getSAE () {
      const start_date = moment(this.chooseDate).startOf('month').format('YYYY-MM-DD')
      const end_date = moment(this.chooseDate).endOf('month').format('YYYY-MM-DD')
      return { start_date, end_date }
    },
    getPrice (data) {
      let modelValue
      if (this.createData.filter(item => item.date === data.day).length > 0) {
        modelValue = this.createData.filter(item => item.date === data.day)[0].price
        if (!Utils.isNull(modelValue) && Number(modelValue) > 0) {
          return `${this.$t('form.hotel.price2')}: $${Number(modelValue)}`
        } else {
          return this.$t('form.hotel.empty')
        }
      } else {
        return this.$t('form.hotel.empty')
      }
      // return (this.createData.filter(item => item.date === data.day).length > 0 && '$' + this.createData.filter(item => item.date === data.day)[0].price / 100) || ''
    },
    getCostPrice (data) {
      let modelValue
      if (this.createData.filter(item => item.date === data.day).length > 0) {
        modelValue = this.createData.filter(item => item.date === data.day)[0].cost_price
        if (!Utils.isNull(modelValue) && Number(modelValue) > 0) {
          return `${this.$t('form.hotel.costPrice')}: $${Number(modelValue)}`
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    addEvent () {
      this.$nextTick(() => {
        // 点击前一个月
        this.prevBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(1)')
        this.prevBtn.addEventListener('click', e => {
          this.init()
        })
        this.nextBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(3)')
        this.nextBtn.addEventListener('click', e => {
          this.init()
        })
        this.today = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(2)')
        this.today.addEventListener('click', e => {
          this.init()
        })
      })
    },
    init () {
      this.$store.state.app.loading = this.$loading(loading)
      const { start_date, end_date } = this.getSAE()
      const params = {
        start_date,
        end_date
      }
      getPolicyCalendarPrice(this.$route.params.id, params).then(res => {
        if (res.status === 200) {
          this.createData = res.data.room_policy_prices.map(item => Object.assign({
            date: item.date,
            price: Utils.isNull(item.price) ? item.price : Number(item.price) / 100,
            cost_price: Utils.isNull(item.cost_price) ? item.cost_price : Number(item.cost_price) / 100
          }, { visible: false }))
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    isDateVisible (day) {
      const { start_date, end_date } = this.getSAE()
      if (moment(day).isAfter(end_date) || moment(day).isBefore(start_date)) return false
      return true
    },
    submitSingPrice (data) {
      if (!this.createData[this.chooseIndex].visible) return
      this.$store.state.app.loading = this.$loading(loading)
      const params = {
        room_policy_prices: [
          {
            date: data,
            price_f: Utils.isNull(this.createData[this.chooseIndex].price) ? null : this.createData[this.chooseIndex].price,
            cost_price_f: Utils.isNull(this.createData[this.chooseIndex].cost_price) ? null : this.createData[this.chooseIndex].cost_price
          }
        ]
      }
      this.submitMethod(params)
    },
    closeSingPrice () {
      this.createData[this.chooseIndex].visible = false
    },
    submitMethod (params, fn) {
      postPolicyCalendarPrice(this.$route.params.id, params).then(res => {
        if (res.status === 200 || res.status === 204) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          if (fn) {
            setTimeout(() => {
              fn()
            }, 800)
          } else this.init()
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const params = {
        room_policy_prices: []
      }
      if (Utils.isNull(data.end_time) || moment(data.begin_time).isSame(data.end_time, 'day')) {
        params.room_policy_prices.push({
          date: data.begin_time,
          price_f: data.price_f,
          cost_price_f: data.cost_price_f
        })
      } else {
        if (moment(data.begin_time).isAfter(data.end_time)) {
          this.$message({
            message: this.$t('message.dataTip'),
            type: 'warning'
          })
          this.$store.state.app.loading.close()
          return
        }
        let i = 0
        while (!moment(moment(data.begin_time).add(i, 'd').format('YYYY-MM-DD')).isAfter(data.end_time, 'day')) {
          params.room_policy_prices.push({
            date: moment(data.begin_time).add(i, 'days').format('YYYY-MM-DD'),
            price_f: data.price_f,
            cost_price_f: data.cost_price_f
          })
          i++
        }
      }
      this.submitMethod(params, () => {
        this.$router.push({
          name: 'policyList',
          params: {
            hotel_room_id_eq: this.$route.params.hotel_room_id_eq
          }
        })
      })
    }
  }
}
</script>
<style lang="scss">
.calendar_price {
  .el-calendar-table:not(.is-range) td.prev, .el-calendar-table:not(.is-range) td.next {
    pointer-events: none;
  }
}
</style>
<style lang="scss" scope>

.el-calendar-table .el-calendar-day {
  height: 140px;
  padding: 25px 8px 8px 14px;
}
.calendar_day {
  display: flex;
  flex-direction: column;
  .calendar_confirm_box {
    display: flex;
    .calendar_confirm {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .calendar_day_top {
    color: #3f586f;
    font-size: 20px;
  }
  .isOpacity {
    opacity: .5;
  }
  .calendar_day_price {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      line-height: 19px;
      margin-top: 15px;
      >span {
        &:nth-child(1) {
          color: #303133;
        }
        &:nth-child(2) {
          color: #606266;
          margin-top: 10px;
        }
      }
    }
}
</style>
