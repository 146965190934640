import i18n from '@/i18n/index'
import moment from 'moment'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 12,
      prop: 'begin_time',
      name: 'date',
      value: moment(new Date()).format('YYYY-MM-DD'),
      label: i18n.t('form.common.startDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.startDate') }) }
        ]
      }
    }, {
      span: 12,
      prop: 'end_time',
      name: 'date',
      label: i18n.t('form.common.endDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'price_f',
      name: 'input',
      type: 'number',
      label: i18n.t('form.hotel.price2'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.priceUnit') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'cost_price_f',
      name: 'input',
      type: 'number',
      label: i18n.t('form.hotel.costPrice'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }]
  }]
}
